<template>
	<div id="body">
		<!-- 近期消费折线图开始 -->
		<div class="topBox">
			<div class="chartTitle">
				<p class="facility">修改密码</p>

			</div>
			<div style="text-align:left;padding:30px;">
				通过手机号码验证，并设置新密码
				<el-form ref="form" :model="form" label-width="120px" :rules="rules" style="margin-top:30px;">
					<el-form-item label="手机号" prop="mobile" required>
						{{userInfo.tel.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}}
					</el-form-item>
					<el-form-item label="短信验证码" prop="vcode" required>
						<el-input style="width:200px;margin-right:10px;" v-model="form.vcode"
							placeholder="请输入4位短信验证码"></el-input>
						<el-button v-if="sec==0" @click="getSmsCode">获取短信验证码</el-button>
						<el-button disabled v-else>{{sec}}秒后重新获取密码</el-button>
					</el-form-item>
					<el-form-item label="设置密码" prop="password" required>
						<el-input style="width:300px;" type="password" v-model="form.password"
							placeholder="请输入新的密码"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="confirmPassword" required>
						<el-input style="width:300px;" type="password" v-model="form.confirmPassword"
							placeholder="请再次输入新的密码"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onConfirm">确认</el-button>
						<el-button @click="onBack">返回</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		setPassSms,
		setPass
	} from '@/api/agentApi.js'
	
	import md5 from 'js-md5'
	export default {
		data() {
			return {
				sec: 0,
				form: {
					password: '',
					confirmPassword: '',
					vcode: ''
				},
				rules: {
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 4,
							message: '最少4个字符',
							trigger: 'blur'
						}
					],
					confirmPassword: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 4,
							message: '最少4个字符',
							trigger: 'blur'
						}
					],
					vcode: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}]
				}
			}
		},
		computed: {
			userInfo() {
				return JSON.parse(localStorage.getItem('userInfo'))
			}
		},
		methods: {
			getSmsCode() {
				this.sec = 60
				setPassSms()
				const timer = setInterval(() => {
					this.sec--
					if (this.sec == 0) clearInterval(timer)
				}, 1000)
			},
			onBack() {
				this.$router.back()
			},
			onConfirm() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						if (this.form.password != this.form.confirmPassword) {
							this.$alert('密码输入不一致', '提示信息', {
								confirmButtonText: '确定'
							});
							return
						}
						setPass({
							code: this.form.vcode,
							pass: md5(this.form.password)
						}).then(res => {
							if (res.status.code == 1) {
								this.$alert('密码修改成功', '提示信息', {
									confirmButtonText: '确定'
								});
								this.$router.push({name: 'login'})
							} else {
								this.$alert(res.status.msg, '提示信息', {
									confirmButtonText: '确定'
								});
							}
						})


					}
				})
			}
		}
	}
</script>

<style lang="scss" scope>
	.topBox {
		width: 100%;
		background: #FFFFFF;
		margin: 0 auto;
		margin-bottom: 20px;

		.chartTitle {
			height: 51px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #E8E8E8;

			.facility {
				font-size: 18px;
				line-height: 48px;
				border-bottom: 2px solid #1890FF;
				color: #1890FF;
				margin-left: 32px;
				font-family: 萍方-简;
				font-weight: normal;
			}

			ul {
				padding: 0;
				margin: 0;
				font-size: 14px;
				margin-right: 25px;

				li {
					float: left;
					list-style: none;
					padding-left: 33px;
				}

				li:hover {
					cursor: pointer
				}
			}
		}
	}
</style>